import { colors } from '@/styles/colors';
import HNText from './HNText';
import { styled } from 'styled-components';

const FocusGrabber = styled(HNText)`
    font-size: 18px;
    line-height: 18px;
    text-align: center;
    background-color: ${colors.primary};
    padding: 8px 12px;
    border-radius: 8px;
    color: ${colors.textSecondary};
    box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;

    @media screen and (max-width: 872px) {
        text-align: center;
    }
`;

export default FocusGrabber;
