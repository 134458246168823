import { styled } from 'styled-components';
import { useEffect, useState } from 'react';
import { seoWords } from '@/utils/seo-utils';

const STATIC_URL = 'https://static.hazmanina.co.il/landing/carousel';
const previewFileName = (file: string) => `${STATIC_URL}/${file}.webp`;
const images = [
    previewFileName('lemons'),
    previewFileName('hasida'),
    previewFileName('cherries'),
    previewFileName('cheers'),
    previewFileName('cupcake'),
    previewFileName('balloons'),
    previewFileName('black_and_white'),
    previewFileName('shadows')
];

const Carousel = ({ className }: { className?: string }) => {
    const [currentIndex, setCurrentIndex] = useState(0);
    useEffect(() => {
        const interval = setInterval(() => {
            setCurrentIndex(prevIndex => (prevIndex + 1) % images.length);
        }, 2000);
        return () => clearInterval(interval);
    }, []);
    const alt = seoWords[currentIndex % seoWords.length];

    return (
        <Container className={className}>
            {images.map((src, index) => (
                <CarouselImage key={index} src={src} $isVisible={currentIndex === index} title={alt} alt={alt} />
            ))}
        </Container>
    );
};

const Container = styled.div`
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 6px;

    box-shadow: rgba(0, 0, 0, 0.19) 0px 10px 20px, rgba(0, 0, 0, 0.23) 0px 6px 6px;
`;

const CarouselImage = styled.img<{ $isVisible: boolean }>`
    position: absolute;
    height: 100%;
    object-fit: cover;
    opacity: ${({ $isVisible }) => ($isVisible ? 1 : 0)};
    transition: opacity 1s ease-in-out;
    border-radius: 6px;
`;

export default Carousel;
