import HNText from '@/components/HNText';
import { styled } from 'styled-components';
import { useEffect, useState } from 'react';
// import ImageStrip from '@/components/landing/ImageStrip';
import Carousel from '@/components/landing/Carousel';
import { AlefFont, Fonts, SecularOneFont } from '@/styles/fonts';
import useAnalytics from '@/components/hooks/useAnalytics';
import { CatalogEvents, LandingPageEvents } from '@/lib/types/mixpanel-events';
import { colors } from '@/styles/colors';
import { Image } from '@nextui-org/react';
import BottomBar from '@/components/BottomBar';
import LandingNavigationBar from '@/components/LandingNavigationBar';
import config from '@/lib/config';
import Link from 'next/link';
import FocusGrabber from '@/components/FocusGrabber';

const STATIC_URL = 'https://static.hazmanina.co.il';

const staticCategoryAssetPathFromName = (name: string) => `${STATIC_URL}/landing/categories/${name}.webp`;

const CATEGORY_ITEMS = [
    {
        presentationName: 'יום הולדת ילדים',
        name: 'kids-birthday',
        alt: 'הזמנה ליום הולדת ילדים'
    },
    {
        presentationName: 'חתונה',
        name: 'weddings',
        alt: 'הזמנה לחתונה'
    },
    {
        presentationName: 'Save the date',
        name: 'save-the-date',
        alt: 'הזמנה ל Save the date'
    },
    {
        presentationName: 'חינה',
        name: 'henna',
        alt: 'הזמנה לחינה'
    },
    {
        presentationName: 'יום הולדת',
        name: 'birthday',
        alt: 'הזמנה ליום הולדת'
    },
    {
        presentationName: 'בת מצווה',
        name: 'bat-mitzva',
        alt: 'הזמנה לבת מצווה'
    },
    {
        presentationName: 'בר מצווה',
        name: 'bar-mitzva',
        alt: 'הזמנה לבר מצווה'
    },
    {
        presentationName: 'ברית/ה',
        name: 'baby',
        alt: 'הזמנה לבר ברית/ה'
    }
] as GridItemProps[];

type GridItemProps = {
    presentationName: string;
    name: string;
    alt: string;
};

const GroupItem = ({ presentationName, name, alt }: GridItemProps) => {
    const { track } = useAnalytics();
    const [isHovering, setIsHovering] = useState<boolean>(false);

    const preview = staticCategoryAssetPathFromName(name);
    const onClick = () => {
        track({
            eventName: CatalogEvents.categoryClicked,
            eventType: 'click',
            properties: {
                source: presentationName
            }
        });
    };

    return (
        <Link href={`/catalog/${name}`}>
            <GridItemContainer onClick={onClick} onMouseOver={() => setIsHovering(true)} onMouseOut={() => setIsHovering(false)}>
                <ItemTitle font={Fonts.SecularOne} isHovering={isHovering}>
                    {presentationName}
                </ItemTitle>
                <StyledGroupImage src={preview} alt={alt} title={alt} width={100} height={100} aspectratio={1} />
            </GridItemContainer>
        </Link>
    );
};

const LandingPage = () => {
    const { track } = useAnalytics();

    useEffect(() => {
        track({
            eventName: LandingPageEvents.loaded,
            eventType: 'load'
        });
    }, [track]);

    return (
        <>
            <LandingNavigationBar />
            <Container>
                <InnerContainer>
                    <TopContainer>
                        <TopInnerContainer>
                            <StyledH1Header>
                                הזמנות לחתונה - הזמנות לבר מצווה - הזמנות לבת מצווה - הזמנות לחינה - הזמנות לברית - הזמנות לימי הולדת
                            </StyledH1Header>
                            <TitleContainer>
                                <Title className={SecularOneFont.className}>הזמנינא</Title>
                                <Subtitle className={AlefFont.className}>הפלטפורמה המתקדמת בישראל לעיצוב הזמנות לאירועים</Subtitle>
                            </TitleContainer>
                            <TopContainerContent>
                                <GridSuperContainer>
                                    <FocusGrabber className={AlefFont.className}>בואו נתחיל! איזה אירוע אתם חוגגים?</FocusGrabber>
                                    <GridContainer>
                                        {CATEGORY_ITEMS.map((item: GridItemProps, index: number) => (
                                            <GroupItem
                                                key={index}
                                                presentationName={item.presentationName}
                                                name={item.name}
                                                alt={item.alt}
                                            />
                                        ))}
                                    </GridContainer>
                                </GridSuperContainer>
                                {/* <StyledStrip /> */}
                                <StyledCarousel />
                            </TopContainerContent>
                        </TopInnerContainer>
                    </TopContainer>
                </InnerContainer>
                <BottomBar />
            </Container>
        </>
    );
};

const Container = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;

    width: 100%;
    min-height: calc(100dvh - ${config.ui.navbarHeight}px);
    margin-block-start: ${config.ui.navbarHeight}px;
`;

const InnerContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    width: 90%;
    flex: 1;
    direction: rtl;
    gap: 20px;

    @media screen and (max-width: 872px) {
        flex-direction: column;
        justify-content: flex-start;
        width: 100%;
    }

    @media screen and (max-width: 717px) {
        height: auto;
    }
`;

const GridSuperContainer = styled.div`
    display: flex;
    width: 45%;
    max-width: 540px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @media screen and (max-width: 1310px) {
        width: 60%;
        min-width: 400px;
    }

    @media screen and (max-width: 1150px) {
        width: 100%;
    }

    @media screen and (max-width: 1100px) {
        width: 100%;
    }

    @media screen and (max-width: 460px) {
        min-width: 100%;
    }
`;

const GridContainer = styled.div`
    display: grid;
    grid-auto-rows: auto-fit;
    justify-content: center;
    grid-template-columns: repeat(4, 100px);
    gap: 45px;
    width: 100%;
    height: 100%;
    margin-block-start: 40px;

    @media screen and (max-width: 1310px) {
        grid-template-columns: repeat(4, 100px);
    }

    @media screen and (max-width: 1100px) {
        grid-template-columns: repeat(3, 100px);
    }

    @media screen and (max-width: 460px) {
        grid-template-columns: repeat(2, 140px);
    }

    @media screen and (max-width: 400px) {
        grid-template-columns: repeat(2, 100px);
    }

    margin-block-end: 20px;
`;

const TopContainer = styled.div`
    width: min(95%, 1800px);
    min-height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @media screen and (max-width: 717px) {
        min-height: auto;
        height: auto;
    }
`;

const TopInnerContainer = styled.div`
    width: 100%;
    height: 100%;

    display: flex;
    flex-direction: column;
    align-items: center;

    @media screen and (max-width: 717px) {
        min-height: auto;
        height: auto;
    }
`;

const StyledH1Header = styled.h1`
    justify-self: flex-start;
    margin-inline-start: 10px;
    margin-inline-end: 10px;
    margin-block-start: 20px;

    font-size: 18px;
    line-height: 18px;
    font-weight: bold;

    padding: 8px;
    border-radius: 6px;
    background-color: ${colors.offWhite};
    border-width: 1px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    @media screen and (max-width: 872px) {
        margin-block-start: 10px;
        margin-block-end: 20px;
        text-align: center;
    }
`;

const TitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    margin-block-end: 10px;
`;

const Title = styled(HNText)`
    font-size: 100px;
    line-height: 100px;
`;

const Subtitle = styled(HNText)`
    width: 100%;
    font-size: 20px;
    text-align: center;

    @media screen and (max-width: 872px) {
        text-align: center;
        font-size: 20px;
    }
`;

const TopContainerContent = styled.div`
    margin-block-start: 40px;
    width: min(100%, 1200px);
    overflow: hidden;
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-around;
    min-height: 500px;

    @media screen and (max-width: 950px) {
        flex-direction: column;
        align-items: center;
    }
`;

const StyledCarousel = styled(Carousel)`
    width: 70%;
    max-width: 300px;
    aspect-ratio: 5 / 7;

    @media screen and (max-width: 950px) {
        margin-block-start: 50px;
        margin-block-end: 50px;
    }
`;

const GridItemContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
`;

const ItemTitle = styled(HNText)<{ isHovering: boolean }>`
    font-size: 17px;
    white-space: nowrap;
    line-height: 17px;
    font-weight: 200;
    letter-spacing: 0.8px;
    color: ${colors.primary};
    font-weight: bold;
    margin-block-end: 10px;
`;

const StyledGroupImage = styled(Image)<{ width: number; aspectratio: number }>`
    width: ${props => props.width}px;
    aspect-ratio: ${props => props.aspectratio};
    background-color: white;
    padding: 32px;

    border-radius: 24px;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    transition: transform 0.1s ease-in-out, scale 0.05s ease-out, box-shadow 0.1s ease-in-out;

    &:hover {
        scale: 1.05;
    }

    &:active {
        transform: translateY(5px);
        scale: 0.97;
    }
`;

export default LandingPage;
