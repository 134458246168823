import { AlefFont } from '@/styles/fonts';
import NavigationBar from './navigation_bars/NavigationBar';
import Link from 'next/link';
import { styled } from 'styled-components';
import { colors } from '@/styles/colors';

const LandingNavigationBar = () => {
    return (
        <NavigationBar>
            <StyledLink href="/contact" className={AlefFont.className}>
                צור קשר
            </StyledLink>
            <StyledLink href="/about" className={AlefFont.className}>
                אודות
            </StyledLink>
            <StyledLink href="/tutorials/weddings" className={AlefFont.className}>
                מדריכים
            </StyledLink>
        </NavigationBar>
    );
};

const StyledLink = styled(Link)`
    font-size: 16px;
    letter-spacing: 1.5px;
    color: ${colors.offWhite};
    height: 100%;

    &:hover {
        color: ${colors.textSecondary};
    }
`;

export default LandingNavigationBar;
